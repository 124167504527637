import { configureStore } from '@reduxjs/toolkit';
import user from './user';
import { save, load } from 'redux-localstorage-simple';
const initialState = { 
  userid: "", 
  session: "",
  Article_id: -1,
  keywoard_id: "-1",
  url: "http://127.0.0.1:8000/",
  lang:"ar",
  current_paragraph:-1,
  dataSnippte:{},
  dataSnippteScarping:{}
};
const store =   configureStore({
  
  reducer: user,
  preloadedState: load(),
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), save()], 
});
store.subscribe(() => {
  save(store.getState()); // Save the state to session storage whenever it changes
});
export default store;