const initialState = { 
    userid: "", 
    session:"",
    Article_id: "-1",
    keywoard_id:-1,
    url:"http://127.0.0.1:8000/",
    lang:"ar",
    current_paragraph:-1,
    dataSnippte:{},
    dataSnippteScarping:{},

  };
  
  const user = (state = initialState, action) => {
    if(Object.keys(state).length === 0){
      state= initialState
    }
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch(action.type) {
      case `SET_SESSION`:
        console.log("---change sension---")
        console.log(action)
        stateCopy.session =action.Session_id
        return stateCopy;
      case "SET_Snippte":
        stateCopy.dataSnippte = action.data
        return stateCopy;
      case "current_paragraph":
        stateCopy.current_paragraph = action.current_paragraph
        return stateCopy;
      case "SetSnippteScarping":
        //console.log("SetSnippteScarping")
        if(stateCopy.dataSnippteScarping === undefined){
          stateCopy.dataSnippteScarping = {}
          stateCopy.dataSnippteScarping[action.index] = action.data
        }
        else{
          stateCopy.dataSnippteScarping[action.index] = action.data
        }
        return stateCopy;
      case 'RestSnippteScarping':
        stateCopy.dataSnippteScarping = {}
        return stateCopy;
      case `SET_Article_ID`:
        stateCopy.Article_id = action.Article_id;
        return stateCopy;
      case `SET_Keywoard_ID`:
        stateCopy.keywoard_id = action.keywoard_id;
        return stateCopy;
      case `SET_Lang`:
        stateCopy.lang =action.lang
        return stateCopy;
      default:
        return stateCopy;
        }
      }
export default user;