const setSession = (Session_id) => {
    return {
      type: `SET_SESSION`,
      Session_id
    }
  }
  
const setArticleId =(Article_id) =>{
    return {
        type: `SET_Article_ID`,
        Article_id
      }
}
const setUserid =(user_id) =>{
    return {
        type: `SET_User_ID`,
        user_id
      }
}
const setKeywoardid =(keywoard_id) =>{
    return {
        type: `SET_Keywoard_ID`,
        keywoard_id
      }
}
const setUrl = (url) => {
  return {
    type: `SET_url`,
    url
  }
}
const setLang = (lang) => {
  return {
    type: `SET_Lang`,
    lang
  }
}
const setDataSnippte = (data) => {
  return {
    type: `SET_Snippte`,
    data
  }
}

const CurrentParagraph = (current_paragraph) => {
  return {
    type: `current_paragraph`,
    current_paragraph
  }}
const SetdataSnippteScarping = (index,data)=>{
  console.log("in scraping")
  console.log(index)
  console.log(data)
  return {
    type: `SetSnippteScarping`,
    index,
    data
  }}
  const RestdataSnippteScarping = ()=>{
    return {
     type: `RestSnippteScarping`,  
    }}
export { 
    setSession,
    setArticleId,
    setUserid,
    setKeywoardid,
    setUrl,
    setLang,
    CurrentParagraph,
    setDataSnippte,
    SetdataSnippteScarping,
    RestdataSnippteScarping
  };
  