import { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
import axios from "axios";
import { setSession } from "./action/index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ShowArticle from "./mainComponents/ShowArticle";
const mapStateToProps = (state) => {
  return {
    userid: state.userid,
    session: state.session,
    url: state.url,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSession: (id) => {
      dispatch(setSession(id));
    },
  };
};
const Login = lazy(() => import('./login'));
const WebPage_Show = ({ setSession, userid, session }) => {
  const [authorized, Setauthorized] = useState(true);

  const [request_count, Setrequest_count] = useState();
  const [request_number, Setrequest_number] = useState();
  let { ArticleId } = useParams();
  console.log(ArticleId);

  if (authorized == true) {
    return <ShowArticle id={ArticleId}></ShowArticle>;
  } else {
    if (authorized == false) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
        <Login request_count={request_count} request_number={request_number}></Login>
        </Suspense>)
    } else {
      <div></div>;
    }
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(WebPage_Show);
